import React, { useState, useEffect } from 'react';
import './sparkle-image.scss';
import grey from "../../assets/ti_grey_sparkle.png";
import no_sparkle from "../../assets/ti_no_sparkle.png";
import sparkle from "../../assets/ti_sparkle.png";



const SparkleImage = () => {
    const [saturation1, setSaturation1] = useState(100);
    const [saturation2, setSaturation2] = useState(0);
    const [saturation3, setSaturation3] = useState(0);
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            if (saturation2 < 100 && animate) {

                setSaturation2(prevSaturation => prevSaturation + 1);

            } else if (saturation3 < 100 && animate) {

                setSaturation3(prevSaturation => prevSaturation + 1);

            } else if (saturation3 === 100 && animate) {

                setAnimate(false);

                let startAgain = true;

                const decrementInterval = setInterval(() => {
                    setSaturation2(prevSaturation => {
                        if (prevSaturation > 0) {

                            return prevSaturation - 1;
                        }

                        return prevSaturation;
                    });

                    if (startAgain) {
                        setSaturation3(prevSaturation => {
                            if (prevSaturation > 0) {

                                return prevSaturation - 1;

                            } else {

                                startAgain = false;

                                return prevSaturation;
                            }
                        });
                    } else {
                        clearInterval(decrementInterval);
                        setTimeout(() => {

                            setSaturation2(0);
                            setSaturation3(0);
                            setAnimate(true);

                        }, 10);
                    }
                }, 30);
            }
        }, 30);

        return () => clearInterval(interval);
    }, [saturation2, saturation3, animate]);


    //console.log(saturation2, saturation3)


    return (
        <div className="fade-animation-container">

            <div className="fade-image-container"
                style={{
                    filter: `saturate(${saturation1}%)`

                }}>

                <img src={grey} alt="Saturate Image" />
            </div>

            <div className="fade-image-container"
                style={{
                    filter: `saturate(${saturation2}%)`,
                    opacity: saturation2 / 100

                }}>

                <img src={no_sparkle} alt="Saturate Image" />
            </div>

            <div className="fade-image-container"
                style={{
                    filter: `saturate(${saturation3}%)`,
                    opacity: saturation3 / 100

                }}>

                <img src={sparkle} alt="Saturate Image" />
            </div>
        </div>
    );
};

export default SparkleImage;