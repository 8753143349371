import React, { useEffect, useState } from 'react';
import "./saturate-image.scss"
import original from "../../assets/ti_Monster_Origionally.png";
import pink from "../../assets/ti_Monster_Pink.png";
import green from "../../assets/ti_Monster_Green.png";
import grey from "../../assets/ti_Monster_Grey.png";

const SaturateImage = ({ imageData }) => {

    const [saturateLevel, setSaturateLevel] = useState(0);
    const [intensity, setIntensity] = useState(1);
    const [currentImage, setCurrentImage] = useState(1);
    let speed = 20;

    useEffect(() => {
        const interval = setInterval(() => {
            setSaturateLevel((prevSaturateLevel) => {
                let newSaturateLevel = prevSaturateLevel + intensity;

                if (newSaturateLevel >= 100 || newSaturateLevel <= 0) {

                    setIntensity((prevIntensity) => -prevIntensity);
                }

                if (newSaturateLevel == 0 && prevSaturateLevel == 1) {


                    if (currentImage === 3) {
                        setCurrentImage(1);
                    }
                    else {
                        setCurrentImage(currentImage + 1);
                    }

                }
                return newSaturateLevel;
            });
        }, speed);

        return () => {
            clearInterval(interval);
        };
    }, [intensity]);




    return (
        <div className="animation-container" style={{ maxWidth: '220px' }}>

            <img src={grey} alt="Saturate Image"
                className='grey-image-style'
            />

            <div className={`image-container ${currentImage === 1 ? 'active' : ''}`}
                style={{
                    filter: `saturate(${saturateLevel}%)`,
                    opacity: saturateLevel/100

                }}>

                <img src={original} alt="Saturate Image"
                    className={currentImage == 1 ? 'show-monster' : 'hide-monster'}
                />
            </div>



            <div className={`image-container ${currentImage === 2 ? 'active' : ''}`}
                style={{
                    filter: `saturate(${saturateLevel}%)`,
                    opacity: saturateLevel/100

                }}>

                <img src={pink} alt="Saturate Image"
                    className={currentImage == 2 ? 'show-monster' : 'hide-monster'}
                />
            </div>



            <div className={`image-container ${currentImage === 3 ? 'active' : ''}`}
                style={{
                    filter: `saturate(${saturateLevel}%)`,
                    opacity: saturateLevel/100

                }}>

                <img src={green} alt="Saturate Image"
                    className={currentImage == 3 ? 'show-monster' : 'hide-monster'}
                />
            </div>

        </div>

    )
};

export default SaturateImage;